import React from 'react';
import { Table } from 'semantic-ui-react'
import ToolSurveyIconTitle from '../../../Custom UI/ToolSurveyIconTitle/ToolSurveyIconTitle'
import '../../../Custom UI/common.scss'
import moment from 'moment-timezone'
import 'antd/dist/antd.css'
import { Switch, DatePicker } from 'antd'
import { toast } from 'react-toastify'
import { NOTIFY_OPTS } from 'constants/Notifiers'
import fileDownload from 'js-file-download'
import { getSectionTitle } from 'utils/Titles'
import {Translation, withTranslation} from "react-i18next";
import i18next from "i18next";
import i18n from "../../../i18n"
import './PatientTools.scss';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {SurveyLockedState} from "../../../actions/surveyActions";
import Icon from "antd/lib/icon";
import Popover from "antd/lib/popover";
import {roleForCompany} from "../../../Helpers/CompanyHelpers";
import {currentDateHumanizedWithoutTimeStamp, momentFromDateTime} from "../../../Helpers/DateHelpers";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import DynamicList from "../../../Lists/DynamicList/DynamicList";
import SendSurveyLinkModal from 'Modals/SendSurveyLinkModal/SendSurveyLinkModal';
import { errorMessageFromServerError } from 'Helpers/Helpers';

const notifyReportFailed = () => toast(<Translation>{(t, {i18n}) => t('REPORT_SENT_FAILED_MESSAGE')}</Translation>, NOTIFY_OPTS.default);
const notifySendSurveyLinkError = (aError) => toast(<Translation>{ (t, { i18n }) => t('SURVEY_LINK_EMAIL_NOT_SENT', {aError:aError}) }</Translation>, NOTIFY_OPTS.autoClose);
const notifySendSurveyLinkSuccess = () => toast(<Translation>{ (t, { i18n }) => t('SURVEY_LINK_EMAIL_SENT') }</Translation>, NOTIFY_OPTS.autoClose);
const notifyReportGenerationSuccess = (report, aTabKey) => toast(<span><Translation>{ (t, { i18n }) => t('NOTIFY_REPORT_GENERATION_SUCCESS', {report:report}) }</Translation> <a target={'_blank'} rel={'noreferrer'} href={'/manage/reports?tab=' + aTabKey}><Translation>{(t, {i18n}) => t('CLICK_HERE')}</Translation></a></span>, NOTIFY_OPTS.defaultFromTop);

class PatientTools extends React.Component {
  
  // Init
  
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      loadFailed:false,
      selectedTool:null,
      toolToRemove:null,
      toolToUpdate:null,
      shouldReload:false,
      selectedSurvey:null,
      selectedFeature:null,
      selectedEConsent:null,
      showSendSurveyLinkModal:false,
      sendSurveyLinkToPatient:false,
      surveyMenuAnchorElement:null,
      featureMenuAnchorElement:null,
      toolConfirmationModalOpen:false,
      surveyConfirmationModalOpen:false,
      eConsentConfirmationModalOpen:false,
      lockSurveyConfirmationModalOpen:false
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.titleFromTool = this.titleFromTool.bind(this);
    this.switchOnChange = this.switchOnChange.bind(this);
    this.fetchPatientQuest = this.fetchPatientQuest.bind(this);
    this.featureSwitchOnChange = this.featureSwitchOnChange.bind(this);
    this.featureMenuButtonClicked = this.featureMenuButtonClicked.bind(this);
    this.removeToolFromUserHelper = this.removeToolFromUserHelper.bind(this);
    this.renderConsentToolCompletedOn = this.renderConsentToolCompletedOn.bind(this);
    this.renderQuestionnaireCompleted = this.renderQuestionnaireCompleted.bind(this);
    this.renderQuestionnaireCompletedOn = this.renderQuestionnaireCompletedOn.bind(this);
    this.renderQuestionnaireStartEndDate = this.renderQuestionnaireStartEndDate.bind(this);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    const {patient} = this.props;
    
    if(prevProps.patient && patient && prevProps.patient.id !== patient.id){
      this.setState({shouldReload:true});
    }
  }
  
  // Methods
  
  handleSave({questId, startDate, endDate, visibility}) {
    let userTool = {
      quest_id: parseInt(questId),
      user_id: parseInt(this.props.patient.id),
      start_date: startDate,
      end_date: endDate,
      visibility: visibility,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    this.props.updateUserTool({userTool}).then(() => {
      if(this.props.patient?.id) {
        this.props.loadPatientMinifiedQuests(this.props.patient.id).then((newResponse) => {
          const tools = newResponse?.data?.data?.user?.quests || [];
          const toolToUpdate = tools.find(tool => tool.id === questId);
          
          if(toolToUpdate) {
            this.setState({toolToUpdate});
          }
        })
      }
    }).catch(error => {
      notifyReportFailed();
    });
  }
  
  fetchPatientQuest(aQuestId){
    const {patient, getQuestById} = this.props;
    
    if(patient && getQuestById){
      getQuestById(patient.id, aQuestId).then((newResponse) => {
        this.setState({toolToUpdate:newResponse.data.data.quest});
      }, (newError) => {
        this.setState({shouldReload:true});
      });
    }
  }
  
  removeToolFromUserHelper(aTool) {
    const {removeToolFromUser, patient} = this.props;
    removeToolFromUser({userId:patient.id, toolId:aTool.id});
    this.setState({selectedTool:null, toolToRemove:aTool});
  }
  
  lockSurvey = (tool) => {
    this.props.updateSurveyResponseLockState(tool.survey_responses[tool.survey_responses.length - 1].id, SurveyLockedState.Locked).then(result => {
      this.setState({shouldReload:true});
    });
    this.setState({selectedSurvey:null});
  };
  
  viewSurveyResponses = (aTool) => {
    if(aTool){
      let surveyId = 0;
  
      if(aTool.survey_version_collection){
        surveyId = aTool.survey_version_collection.id;
      }
      if(surveyId > 0){
        this.props.onOpenDrawer(surveyId, parseInt(this.props.patient.id), aTool.title, true, null, true, null);
      }
    }
    this.setState({selectedTool:null});
  };
  
  openSurvey = (aTool) => {
    if(aTool){
      let surveyId = 0;
      
      if(aTool.survey){
        surveyId = aTool.survey.id;
      }
      else if(aTool.objectives != null && aTool.objectives.length > 0){
        for(let index = 0; index < aTool.objectives.length; index++){
          if(aTool.objectives[index].type === "SurveyObjective"){
            surveyId = aTool.objectives[index].survey_id;
            break;
          }
        }
      }
      if(surveyId > 0){
        let editable = true;
  
        if(aTool.survey_responses && aTool.survey_responses.length > 0){
          if(aTool.survey_responses[aTool.survey_responses.length - 1].progress === 100){
            if(aTool.survey_responses[aTool.survey_responses.length - 1].lock_state === SurveyLockedState.Locked){
              editable = false;
            }
          }
        }
        this.props.onOpenDrawer(surveyId, parseInt(this.props.patient.id), aTool.title, editable, null, false, null, () => this.fetchPatientQuest(aTool.id));
      }
    }
    this.setState({selectedTool:null});
  };
  
  getCompletedInfoFromTool(tool, patient){
    const {t} = this.props;
    let by = t('BY', {i18n});
    
    if(tool == null || tool.survey_responses == null || tool.survey_responses.length <= 0){
      return '';
    }
    let survey_response = tool.survey_responses[tool.survey_responses.length - 1];
    
    if(survey_response.completed_by_admin){
      if(survey_response.admin_name && survey_response.admin_name.trim()) {
        return by +' '+ survey_response.admin_name;
      }
      else {
        return by + ' ' + survey_response.admin_email;
      }
    }
    else if(survey_response.complete){
      return by +' '+ patient.first_name + ' ' + patient.last_name;
    }
  }
  
  switchOnChange(aValue, aTool){
    this.handleSave({questId:aTool.id, endDate:null, startDate:null, visibility:aValue});
  }
  
  featureSwitchOnChange(checked, event) {
    const featureId = event.currentTarget.name;
    this.props.updateFeatureToUser(this.props.patient.id, featureId, checked);
  }
  
  onStartValChange = (date, toolId) => {
    this.handleSave({ questId: toolId, startDate: date ? date.format() : null });
  };
  
  onEndValChange = (date, toolId) => {
    this.handleSave({ questId: toolId, endDate: date ? date.format() : null });
  };
  
  disabledStartDate = (currentDate, tool) => {
    const startDate = currentDate;
    const endDate = tool.end_date ? moment(tool.end_date, 'YYYY-MM-DD hh:mm:ss TZD') : null;
    
    if(!startDate || !endDate){
      return false;
    }
    return startDate.valueOf() > endDate.valueOf();
  };
  
  disabledEndDate = (currentDate, tool) => {
    const endDate = currentDate;
    const startDate = tool.start_date ? moment(tool.start_date, 'YYYY-MM-DD hh:mm:ss TZD') : null;
    
    if(!endDate || !startDate){
      return false;
    }
    return endDate.valueOf() <= startDate.valueOf();
  };
  
  canChangeToolDates = (tool) => {
    let currentRole = roleForCompany(this.props.company);
    return tool.has_trigger_quests !== true || ['admin', 'admin_level_4'].includes(currentRole);
  };
  
  questEnabled = (visibility) => (visibility === true) || (visibility === null);
  
  toolPushed = (toolStartDate, toolEndDate, toolVisibility) => {
    let startDate = moment(toolStartDate).startOf('day');
    let endDate = moment(toolEndDate).endOf('day');
    const now = moment().startOf('day');
    
    return (toolVisibility && ((!endDate.valueOf() && now.isSameOrAfter(startDate))
      || (!startDate.valueOf() && now.isSameOrBefore(endDate))
      || (startDate.valueOf() && endDate.valueOf() && now.isSameOrAfter(startDate) && now.isSameOrBefore(endDate))
      || (!startDate.valueOf() && !endDate.valueOf())))
  };
  
  isSurveyComplete = (aTool) => {
    if(aTool && aTool.survey_responses && aTool.survey_responses.length > 1){
      return true;
    }
    return aTool && aTool.survey_responses && aTool.survey_responses[0]?.complete;
  };
  
  canViewSurvey = (aTool) => {
    let returnValue = true;
    let currentRole = roleForCompany(this.props.company);
    
    if(!aTool){
      returnValue = false;
    }
    if(currentRole === 'admin_level_1'){
      if(aTool && aTool.survey && aTool.survey.has_consent_question){
        returnValue = false;
      }
    }
    return returnValue;
  };
  
  patientId = (aPatient) => {
    const {t} = this.props;
    let returnValue = '';
    let edcIDString = t('EDC_ID_UPPERCASE', {i18n});
    let siteIDString = t('SITE_ID', {i18n});
    
    if(aPatient){
      if(aPatient.edc_id){
        if(aPatient.site_id) {
          returnValue = `${siteIDString}: ${aPatient.site_id} ${edcIDString}: ${aPatient.edc_id}`;
        }
        else{
          returnValue = `${edcIDString}: ${aPatient.edc_id}`;
        }
      }
      else{
        returnValue = aPatient.id;
      }
    }
    return returnValue;
  };
  
  requestPatientSurveyReportCSV = (surveyTool) => {
    const {patient, company} = this.props;
    
    const reportReqParams = {
      user_id:patient.id,
      quest_id:surveyTool.id,
      company_id:company && company.id
    };
    global.api.post("/api/tool_stats/patient_survey_version_collection_report", reportReqParams, {responseType:'blob'})
    .then((result) =>
      fileDownload(result.data, `survey_responses_${surveyTool.title}_${currentDateHumanizedWithoutTimeStamp()}_${this.patientId(patient)}.zip`))
    .catch((error) => notifyReportFailed());
  };
  
  requestConsentFormPDF = (consentTool) => {
    const {patient, company} = this.props;
    
    const reportReqParams = {
      user_id:patient.id,
      quest_id:consentTool.id,
      company_id:company && company.id
    };
    global.api.post("/api/tool_stats/patient_consent_report_pdf", reportReqParams, {responseType:'blob'})
    .then((result) =>
      fileDownload(result.data, `consent_report_${consentTool.title}_${currentDateHumanizedWithoutTimeStamp()}_${this.patientId(patient)}.pdf`, 'application/pdf'))
    .catch((error) => notifyReportFailed());
  };
  
  requestPatientSurveyReportPDF = (surveyTool) => {
    const {patient, company} = this.props;
    
    const reportReqParams = {
      user_id:patient.id,
      quest_id:surveyTool.id,
      company_id:company && company.id,
      generate_in_background:'true'
    };
    global.api.post("/api/tool_stats/patient_survey_version_collection_report_pdf", reportReqParams, {responseType: 'blob'})
    .then((result) => {
      let fileName = `survey_responses_${surveyTool.title}_${currentDateHumanizedWithoutTimeStamp()}_${this.patientId(patient)}.zip`;
      notifyReportGenerationSuccess(fileName, 'GENERATED_REPORTS');
    })
    .catch((error) => notifyReportFailed());
  };
  
  closeMenu(){
    this.setState({selectedTool:null, selectedSurvey:null, selectedEConsent:null, selectedFeature:null,
      surveyConfirmationModalOpen:false, eConsentConfirmationModalOpen:false, lockSurveyConfirmationModalOpen:false});
  }
  
  featureMenuButtonClicked(aEvent, aFeature){
    aEvent.preventDefault();
    aEvent.stopPropagation();
    this.setState({featureMenuAnchorElement:aEvent.currentTarget, selectedFeature:aFeature});
  }
  
  titleFromTool(aTool){
    let returnValue = '';
    
    if(aTool && aTool.title && aTool.title.length > 0){
      returnValue = aTool.title;
    }
    return returnValue;
  }
  
  renderToolTitle(aTool){
    return (
      <ToolSurveyIconTitle toolTitle={this.titleFromTool(aTool)}
                           toolIconName={aTool.icon}
      />
    );
  }
  
  renderQuestionnaireStartEndDate(aQuestionnaire, disabled){
    let startDate = aQuestionnaire.start_date ? moment(aQuestionnaire.start_date, 'YYYY-MM-DD hh:mm:ss TZD') : null;
    let endDate = aQuestionnaire.end_date ? moment(aQuestionnaire.end_date, 'YYYY-MM-DD hh:mm:ss TZD') : null;
  
    return (
      this.questEnabled(aQuestionnaire.visibility) ?
        <span>
          <DatePicker value={startDate}
                      format="MM-DD-YYYY"
                      disabled={disabled}
                      onChange={(date) => this.onStartValChange(date, aQuestionnaire.id)}
                      className='tool-start-end-date-picker'
                      placeholder="mm/dd/yyyy"
                      disabledDate={(currentDate) => this.disabledStartDate(currentDate, aQuestionnaire)}
          />
          
          &nbsp;
          -
          &nbsp;
          
          <DatePicker value={endDate}
                      format="MM-DD-YYYY"
                      disabled={disabled}
                      onChange={(date) => this.onEndValChange(date, aQuestionnaire.id)}
                      className='tool-start-end-date-picker'
                      placeholder="mm/dd/yyyy"
                      disabledDate={(currentDate) => this.disabledEndDate(currentDate, aQuestionnaire)}
          />
        </span>
        :
        <Translation>{(t, {i18n}) => t('SURVEY_NOT_VISIBLE')}</Translation>
    );
  }
  
  renderQuestionnaireCompleted(aQuestionnaire){
    return (
      (aQuestionnaire.survey_responses && aQuestionnaire.survey_responses.length > 0) ?
        <div>
          {aQuestionnaire.admin_only ?
            <div style={{display:'flex', justifyContent:'start', alignItems:'center'}}>
              <span className="patient-tools-admin-progress-value">
                {aQuestionnaire.survey_responses[aQuestionnaire.survey_responses.length - 1].progress}%

                {aQuestionnaire.survey_responses.length > 1 ?
                  <>
                    <br/>
                    
                    ({aQuestionnaire.survey_responses[aQuestionnaire.survey_responses.length - 1].complete ? aQuestionnaire.survey_responses.length : aQuestionnaire.survey_responses.length - 1})
                  </>
                  :
                  null
                }
              </span>
            
              {aQuestionnaire.survey_responses[aQuestionnaire.survey_responses.length - 1].completed_at ?
                <Popover content={momentFromDateTime(aQuestionnaire.survey_responses[aQuestionnaire.survey_responses.length - 1].completed_at)}
                         placement="bottom">
                  <div className="patient-tools-notes-popover-button">
                    <Icon type="ellipsis" />
                  </div>
                </Popover>
                :
                null
              }
            </div>
            :
            <div style={{display:'flex', alignItems:'center'}}>
              <span style={{width:'37px', flex:'0 0 37px'}}>
                <div className="patient-tools-progress-value">
                  {aQuestionnaire.survey_responses[aQuestionnaire.survey_responses.length - 1].progress}%
  
                  {aQuestionnaire.survey_responses.length > 1 ?
                    <>
                      <br/>
      
                      ({aQuestionnaire.survey_responses[aQuestionnaire.survey_responses.length - 1].complete ? aQuestionnaire.survey_responses.length : aQuestionnaire.survey_responses.length - 1})
                    </>
                    :
                    null
                  }
                </div>
  
                {aQuestionnaire.survey_responses[aQuestionnaire.survey_responses.length - 1].completed_at ?
                  <Popover
                    content={momentFromDateTime(aQuestionnaire.survey_responses[aQuestionnaire.survey_responses.length - 1].completed_at)}
                    placement="bottom">
                    <div className="patient-tools-notes-popover-button">
                      <Icon type="ellipsis"/>
                    </div>
                  </Popover>
                  :
                  null
                }
              </span>
            
              &nbsp;
            
              {aQuestionnaire.survey_responses[aQuestionnaire.survey_responses.length - 1].progress < 100 || aQuestionnaire.survey_responses[aQuestionnaire.survey_responses.length - 1].lock_state === SurveyLockedState.Unlocked ?
                <span>
                  <img src='/imgs/app/unlocked.svg'
                       alt='adminLocked'
                       style={{width:'25px', height:'25px'}}
                  />
                </span>
                :
                aQuestionnaire.survey_responses[aQuestionnaire.survey_responses.length - 1].lock_state === SurveyLockedState.AdminsOnly ?
                  <span>
                    <img src='/imgs/app/admin-locked.svg'
                         style={{width:'25px', height:'25px'}}
                         alt='adminLocked'/>
                  </span>
                  :
                  aQuestionnaire.survey_responses[aQuestionnaire.survey_responses.length - 1].lock_state === SurveyLockedState.Locked ?
                    <span>
                      <img src='/imgs/app/locked.svg'
                           style={{width:'25px', height:'25px'}}
                           alt='adminLocked'/>
                    </span>
                    :
                    null
              }
            </div>
          }
        </div>
        :
        null
    );
  }
  
  renderQuestionnaireCompletedOn(aQuestionnaire, showCompletedInfo){
    return (
      (aQuestionnaire && aQuestionnaire.survey_responses && aQuestionnaire.survey_responses[0] && this.isSurveyComplete(aQuestionnaire)) ?
        <div>
          {momentFromDateTime(aQuestionnaire.survey_responses[aQuestionnaire.survey_responses.length - 1].updated_at)}
        
          <br/>
        
          {showCompletedInfo && this.getCompletedInfoFromTool(aQuestionnaire, this.props.patient)}
        </div>
        :
        null
    );
  }
  
  renderConsentToolCompletedOn(aTool){
    return (
      aTool.tool_last_used ?
        <span>
          {momentFromDateTime(aTool.tool_last_used)}
        </span>
        :
        null
    );
  }
  
  render() {
    const {company, patient, toolType, className, explanationKey, fetchQuestsByPatientId, sendSurveyLink, loadPatientHCPContacts} = this.props;
    const {selectedSurvey, shouldReload, selectedTool, toolConfirmationModalOpen, selectedEConsent,
      eConsentConfirmationModalOpen, surveyConfirmationModalOpen, lockSurveyConfirmationModalOpen, toolToRemove, toolToUpdate,
      showSendSurveyLinkModal, sendSurveyLinkToPatient} = this.state;
    
    const tools = patient && patient.quests;
    const features = patient && patient.hs_features;
    
    const currentRole = roleForCompany(company);
    
    const assignedFeaturesPermissions = ["admin", "admin_level_2", "provider", "admin_level_3", "admin_level_4", "admin_level_10"];
    const downloadButtonPermissions = ["admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin", "admin_level_10"];
    const makeAnEntryPermissions = ["admin_level_2", "admin_level_3", "admin_level_4", "admin", "admin_level_9"];
    const lockButtonPermissions = ["admin", "admin_level_1", "admin_level_4"];
    const viewSurveyResponsesPermissions = ["admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin", "admin_level_10"];
    const assignedSurveyDeletionPermissions = ["admin", "admin_level_4"];
    const assignedConsentPermissions = ["admin", "provider", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_5", "admin_level_10"];
    const assignedSurveysPermissions = ["admin", "provider", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_10"];
    const patientSurveysPermissions = ["admin", "provider", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_9", "admin_level_10"];
    const assignedToolsPermissions = ["admin", "provider", "admin_level_1", "admin_level_2", "admin_level_3", "admin_level_4", "admin_level_10"];
    const removeButtonPermissions = ["admin_level_1"];
    const hscConsentOnlyPermissions = ["admin_level_9"];
    const editPermissions = ["admin", "admin_level_2", "admin_level_4", "admin_level_10"];
    
    let showFeatures = (!toolType || toolType === 'Features');
    let showSurvey = (!toolType || toolType === 'Survey');
    let showPatientOnly = (!toolType || toolType === 'PatientOnly');
    let showAdminOnly = (!toolType || toolType === 'AdminOnly');
    let showConsent = (!toolType || toolType === 'Consent');
    
    let questionnaireMenuItemsArray = [];
  
    if(downloadButtonPermissions.includes(currentRole)){
      questionnaireMenuItemsArray.push({title:'DOWNLOAD_REPORT_CSV', clickAction:(aSurvey) => {
          this.requestPatientSurveyReportCSV(aSurvey);
        }, isValid:(aSurvey) => aSurvey && this.canViewSurvey(aSurvey) && this.isSurveyComplete(aSurvey)});
    }
    if(downloadButtonPermissions.includes(currentRole)){
      questionnaireMenuItemsArray.push({title:'DOWNLOAD_REPORT_PDF', clickAction:(aSurvey) => {
          this.requestPatientSurveyReportPDF(aSurvey);
        }, isValid:(aSurvey) => aSurvey && this.canViewSurvey(aSurvey) && this.isSurveyComplete(aSurvey)});
    }
    if(lockButtonPermissions.includes(currentRole)){
      questionnaireMenuItemsArray.push({title:'LOCK', clickAction:(aSurvey) => {
          this.setState({selectedSurvey:aSurvey, lockSurveyConfirmationModalOpen:true});
        }, isValid:(aSurvey) => aSurvey && this.isSurveyComplete(aSurvey)
          && aSurvey.survey_responses.length > 0
          && aSurvey.survey_responses[0].progress >= 100
          && aSurvey.survey_responses[0].lock_state !== SurveyLockedState.Locked});
    }
    if(viewSurveyResponsesPermissions.includes(currentRole)){
      questionnaireMenuItemsArray.push({title:'VIEW_SURVEY_RESPONSES', clickAction:(aSurvey) => {
          this.setState({selectedSurvey:aSurvey});
          this.viewSurveyResponses(aSurvey);
        }, isValid:(aSurvey) => aSurvey && this.canViewSurvey(aSurvey)});
    }
    if(makeAnEntryPermissions.includes(currentRole)){
      questionnaireMenuItemsArray.push({title:'VIEW', clickAction:(aSurvey) => {
          this.setState({selectedSurvey:aSurvey});
          this.openSurvey(aSurvey);
        }, isValid:(aSurvey) => aSurvey && this.canViewSurvey(aSurvey)
          && aSurvey.survey_responses.length > 0
          && aSurvey.survey_responses[0].lock_state === SurveyLockedState.Locked});
      questionnaireMenuItemsArray.push({title:'MAKE_AN_ENTRY_TEXT', clickAction:(aSurvey) => {
          this.setState({selectedSurvey:aSurvey});
          this.openSurvey(aSurvey);
        }, isValid:(aSurvey) => aSurvey && this.canViewSurvey(aSurvey) && !(aSurvey
          && aSurvey.survey_responses.length > 0
          && aSurvey.survey_responses[0].lock_state === SurveyLockedState.Locked)});
      questionnaireMenuItemsArray.push({title:'SEND_ADMIN_SURVEY_LINK', clickAction:(aSurvey) => {
        loadPatientHCPContacts(Number(patient.id));
        this.setState({selectedSurvey:aSurvey, surveyMenuAnchorElement:null, showSendSurveyLinkModal:true, sendSurveyLinkToPatient:false});
      }, isValid:(aSurvey) => this.canViewSurvey(aSurvey)});
    }
    if(assignedSurveyDeletionPermissions.includes(currentRole)){
      questionnaireMenuItemsArray.push({title:'REMOVE', clickAction:(aSurvey) => this.setState({selectedSurvey:aSurvey, surveyConfirmationModalOpen:true})});
    }
    
    let startEndDateColumn = {key:'start_end_date', columnWeight:2.5, columnNameKey:'START_END_DATE', templateCell:(aQuestionnaire) => this.renderQuestionnaireStartEndDate(aQuestionnaire, (!editPermissions.includes(currentRole) || (this.canChangeToolDates(aQuestionnaire) === false)))};
    
    let questionnaireColumnsArray = [];
    questionnaireColumnsArray.push({key:'questionnaire_name', columnWeight:2.5, columnNameKey:'QUESTIONNAIRE_NAME', templateCell:(aQuestionnaire) => this.renderToolTitle(aQuestionnaire)});
    questionnaireColumnsArray.push(startEndDateColumn);
    questionnaireColumnsArray.push({key:'completed', columnWeight:1, columnNameKey:'COMPLETED', templateCell:(aQuestionnaire) => this.renderQuestionnaireCompleted(aQuestionnaire)});
    questionnaireColumnsArray.push({key:'completed_on', columnWeight:1, columnNameKey:'COMPLETED_ON', templateCell:(aQuestionnaire) => this.renderQuestionnaireCompletedOn(aQuestionnaire, !removeButtonPermissions.includes(currentRole))});
    
    if(showPatientOnly){
      questionnaireColumnsArray.push({key:'visibility', columnWeight:1, columnNameKey:'ENABLED', propertiesArray:['visibility'], type:'switch',
        handleSave:(aValue, aObject) => {
          this.switchOnChange(aValue, aObject);
        }, disabled:() => !editPermissions.includes(currentRole)});
    }
  
    let toolsColumnsArray = [];
    toolsColumnsArray.push({key:'tool_name', columnWeight:2.5, columnNameKey:'TOOL_NAME', templateCell:(aTool) => this.renderToolTitle(aTool)});
    toolsColumnsArray.push({key:'visibility', columnWeight:1, columnNameKey:'ENABLED', propertiesArray:['visibility'], type:'switch',
      handleSave:(aValue, aObject) => {
        this.switchOnChange(aValue, aObject);
      }, disabled:() => !editPermissions.includes(currentRole)});
    let toolsMenuItemsArray=[{title:'REMOVE', clickAction:(aTool) => this.setState({selectedTool:aTool, toolConfirmationModalOpen:true})}];
    
    let consentColumnsArray = [];
    consentColumnsArray.push({key:'consent_name', columnWeight:2.5, columnNameKey:'CONSENT_NAME', templateCell:(aTool) => this.renderToolTitle(aTool)});
    consentColumnsArray.push(startEndDateColumn);
    consentColumnsArray.push({key:'completed_on', columnWeight:1, columnNameKey:'COMPLETED_ON', templateCell:(aTool) => this.renderConsentToolCompletedOn(aTool)});
    consentColumnsArray.push({key:'visibility', columnWeight:1, columnNameKey:'ENABLED', propertiesArray:['visibility'], type:'switch',
      handleSave:(aValue, aObject) => {
        this.switchOnChange(aValue, aObject);
      }, disabled:() => !editPermissions.includes(currentRole)});
    
    let consentMenuItemsArray = [];
    consentMenuItemsArray.push({title:'DOWNLOAD', clickAction:(aTool) => {
        this.requestConsentFormPDF(aTool);
      }, isValid:(aTool) => aTool && aTool.tool_last_used});
    consentMenuItemsArray.push({title:'REMOVE', clickAction:(aTool) => this.setState({selectedEConsent:aTool, eConsentConfirmationModalOpen:true})});
    
    const showMenuButton = editPermissions.includes(currentRole);
    
    // consent switch code
    // <Switch name={tool.id}
    //         checked={this.state[`toolSwitch${tool.id}`] || ((tool.visibility === true) || (tool.visibility === null))}
    //         disabled={!editPermissions.includes(currentRole)}
    //         onChange={this.switchOnChange}
    //         checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
    //         unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
    // />
    
    let objectsFilter = null;
    
    if(currentRole && (hscConsentOnlyPermissions.includes(currentRole) || removeButtonPermissions.includes(currentRole))){
      objectsFilter = (aQuest) => {
        let conditions = true;
  
        if(hscConsentOnlyPermissions.includes(currentRole)){
          conditions = conditions && aQuest.has_hsc_consent_question;
        }
        if(removeButtonPermissions.includes(currentRole)){
          conditions = conditions && this.isSurveyComplete(aQuest);
        }
        return conditions;
      }
    }
    
    return (
      <div className={className}>
        {/*Patient Profile -> App Configuration -> Features Tab*/}
        {showFeatures ? (
            <div>
              {assignedFeaturesPermissions.includes(currentRole) ? (
                  <Table unstackable singleLine selectable className='table-no-border patient-tools-container'>
                    <Table.Header>
                      <Table.Row className='header-row-table'>
                        <Table.HeaderCell width={2}><Translation>{ (t, { i18n }) => t('FEATURE_NAME') }</Translation></Table.HeaderCell>
                        <Table.HeaderCell width={1}><Translation>{ (t, { i18n }) => t('ENABLED') }</Translation></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    {features && features.length > 0 &&
                    (<Table.Body>
                        {features.map((feature) => (
                          <Table.Row key={feature.id} className="table-body-row">
                            <Table.Cell className="title-container">
                              <ToolSurveyIconTitle toolTitle={feature.title} faIcon={(feature.title === 'Apple Health') ? 'Apple': 'Sync'} />
                            </Table.Cell>
                            <Table.Cell>
                              <div className="patient-tools-end-cell">
                                <Switch name={feature.id}
                                        checked={feature.enabled}
                                        disabled={!editPermissions.includes(currentRole)}
                                        onChange={this.featureSwitchOnChange}
                                        checkedChildren={<Translation>{ (t, { i18n }) => t('YES_PROPER_CAPITALIZED') }</Translation>}
                                        unCheckedChildren={<Translation>{ (t, { i18n }) => t('NO_PROPER_CAPITALIZED') }</Translation>}
                                />
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    )
                    }
                    <Menu id="simple-menu"
                          anchorEl={this.state.featureMenuAnchorElement}
                          keepMounted
                          open={Boolean(this.state.featureMenuAnchorElement)}
                          onClose={() => this.closeMenu()}>
                      <MenuItem style={{position: 'relative'}} onClick={() => this.closeMenu()}>
                        <Translation>{ (t, { i18n }) => t('CHANGE_ROLE')}</Translation>
                      </MenuItem>
                    </Menu>
                  </Table>
                )
                :
                <div className="no-information-text">
                  {getSectionTitle(i18next.t('ASSIGNED_FEATURES_BLOCKED'))}
                </div>}
            </div>
          )
          :
          null
        }

        {/*Patient Profile -> App Configuration -> eConsents Tab*/}
        {showConsent ? (
            <div className="patient-tools-list">
              {assignedConsentPermissions.includes(currentRole) ?
                <>
                  <DynamicList id="consent-list"
                               limit={5}
                               didReload={() => this.setState({shouldReload:false})}
                               menuCancel={() => this.setState({selectedEConsent:null})}
                               shouldReload={shouldReload}
                               columnsArray={consentColumnsArray}
                               fetchObjects={(aLimit, aOffset, aSearchString) => fetchQuestsByPatientId(patient.id, aLimit, aOffset, false, aSearchString, 'Consent')}
                               explanationKey={explanationKey}
                               menuItemsArray={showMenuButton ? consentMenuItemsArray: null}
                               objectToDelete={toolToRemove}
                               objectToReplace={toolToUpdate}
                               responseTotalKeysArray={['data', 'data', 'user_quests', 'total']}
                               finishedDeletingObject={() => this.setState({toolToRemove:null})}
                               finishedReplacingObject={() => this.setState({toolToUpdate:null})}
                               responseObjectKeysArray={['data', 'data', 'user_quests', 'quests']}
                  />
    
                  <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:(selectedEConsent ? selectedEConsent.title : '')})}
                                     isOpen={eConsentConfirmationModalOpen}
                                     reject={() => this.setState({eConsentConfirmationModalOpen:false})}
                                     confirm={() => {
                                       this.removeToolFromUserHelper(selectedEConsent);
                                       this.setState({eConsentConfirmationModalOpen:false});
                                       this.closeMenu();
                                     }}
                  />
                </>
                :
                <div className="no-information-text">
                  <Translation>{ (t, { i18n }) => t('ASSIGNED_CONSENT_BLOCKED')}</Translation>
                </div>
              }
            </div>
          )
          :
          null
        }

        {/*Patient Profile -> Study Management -> Questionnaires Tab*/}
        {showPatientOnly ?
          <div className="patient-tools-list">
            {patientSurveysPermissions.includes(currentRole) ?
              <DynamicList id="questionnaire-list"
                           limit={5}
                           didReload={() => this.setState({shouldReload:false})}
                           menuCancel={() => this.setState({selectedSurvey:null})}
                           shouldReload={shouldReload}
                           columnsArray={questionnaireColumnsArray}
                           fetchObjects={(aLimit, aOffset, aSearchString) => fetchQuestsByPatientId(patient.id, aLimit, aOffset, false, aSearchString, 'Survey')}
                           objectsFilter={objectsFilter}
                           explanationKey={explanationKey}
                           menuItemsArray={questionnaireMenuItemsArray.length > 0 ? questionnaireMenuItemsArray : null}
                           objectToDelete={toolToRemove}
                           objectToReplace={toolToUpdate}
                           responseTotalKeysArray={['data', 'data', 'user_quests', 'total']}
                           finishedDeletingObject={() => this.setState({toolToRemove:null})}
                           finishedReplacingObject={() => this.setState({toolToUpdate:null})}
                           responseObjectKeysArray={['data', 'data', 'user_quests', 'quests']}
              />
              :
              <div className="no-information-text">
                {getSectionTitle(i18next.t('PATIENT_SURVEYS_BLOCKED'))}
              </div>
            }
          </div>
          :
          null
        }

        {/*Patient Profile -> Study Management -> Admin Questionnaires Tab*/}
        {showAdminOnly ?
          <div className="patient-tools-list">
            {assignedSurveysPermissions.includes(currentRole) ?
              <DynamicList id="admin-only-questionnaire-list"
                           limit={5}
                           didReload={() => this.setState({shouldReload:false})}
                           menuCancel={() => this.setState({selectedSurvey:null})}
                           shouldReload={shouldReload}
                           columnsArray={questionnaireColumnsArray}
                           fetchObjects={(aLimit, aOffset, aSearchString) => fetchQuestsByPatientId(patient.id, aLimit, aOffset, true, aSearchString, 'Survey')}
                           explanationKey={explanationKey}
                           menuItemsArray={questionnaireMenuItemsArray.length > 0 ? questionnaireMenuItemsArray : null}
                           objectToDelete={toolToRemove}
                           objectToReplace={toolToUpdate}
                           responseTotalKeysArray={['data', 'data', 'user_quests', 'total']}
                           finishedDeletingObject={() => this.setState({toolToRemove:null})}
                           finishedReplacingObject={() => this.setState({toolToUpdate:null})}
                           responseObjectKeysArray={['data', 'data', 'user_quests', 'quests']}
              />
              :
              <div className="no-information-text">
                {getSectionTitle(i18next.t('ASSIGNED_SURVEYS_BLOCKED'))}
              </div>
            }
          </div>
          :
          null
        }

        {/*Patient Profile -> App Configuration -> Tools Tab*/}
        {showSurvey ? (
            <div className="patient-tools-list">
              {assignedToolsPermissions.includes(currentRole) ?
                <DynamicList id="tools-list"
                             limit={5}
                             didReload={() => this.setState({shouldReload:false})}
                             menuCancel={() => this.setState({selectedSurvey:null})}
                             shouldReload={shouldReload}
                             columnsArray={toolsColumnsArray}
                             fetchObjects={(aLimit, aOffset, aSearchString) => fetchQuestsByPatientId(patient.id, aLimit, aOffset, false, aSearchString, 'Tool')}
                             explanationKey={explanationKey}
                             menuItemsArray={showMenuButton ? toolsMenuItemsArray : null}
                             objectToDelete={toolToRemove}
                             objectToReplace={toolToUpdate}
                             responseTotalKeysArray={['data', 'data', 'user_quests', 'total']}
                             finishedDeletingObject={() => this.setState({toolToRemove:null})}
                             finishedReplacingObject={() => this.setState({toolToUpdate:null})}
                             responseObjectKeysArray={['data', 'data', 'user_quests', 'quests']}
                />
                :
                <div className="no-information-text">
                  {getSectionTitle(i18next.t('ASSIGNED_TOOLS_BLOCKED'))}
                </div>
              }
            </div>
          )
          :null
        }
        
        <SendSurveyLinkModal isOpen={showSendSurveyLinkModal}
                             patient={patient}
                             handleFinish={(aEmail, aEmailBody, aEmailSubject, aSubjectIdentificationMessage) => {
                               let email = aEmail;
                               let userId = null;
                               let patientId = patient.id;
                               let subjectIdentificationMessage = aSubjectIdentificationMessage;

                               if(sendSurveyLinkToPatient){
                                 email = null;
                                 userId = patient.id;
                                 patientId = null;
                                 subjectIdentificationMessage = null;
                               }
                               sendSurveyLink(email, userId, selectedSurvey.survey.id, patientId, aEmailSubject, aEmailBody, null, subjectIdentificationMessage).then(newResult => {
                                 notifySendSurveyLinkSuccess();
                               }, (newError) => {
                                 notifySendSurveyLinkError(errorMessageFromServerError(newError));
                               });
                               this.setState({selectedSurvey:null, showSendSurveyLinkModal:false, sendSurveyLinkToPatient:false});
                             }}
                             requiresEmail={!sendSurveyLinkToPatient}
                             saveButtonTitle={<Translation>{(t, {i18n}) => t('SEND')}</Translation>}
                             handleCloseModal={() => this.setState({selectedSurvey:null, showSendSurveyLinkModal:false, sendSurveyLinkToPatient:false})}
        />
        
        <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:(selectedTool ? selectedTool.title : '')})}
                           isOpen={toolConfirmationModalOpen}
                           reject={() => this.setState({toolConfirmationModalOpen:false})}
                           confirm={() => {
                             this.removeToolFromUserHelper(selectedTool);
                             this.setState({toolConfirmationModalOpen:false});
                             this.closeMenu();
                           }}
        />
        
        <ConfirmationModal title={i18next.t('REMOVE_NAME', {aName:(selectedSurvey ? selectedSurvey.title : '')})}
                           isOpen={surveyConfirmationModalOpen}
                           reject={() => this.closeMenu()}
                           confirm={() => {
                             this.removeToolFromUserHelper(selectedSurvey);
                             this.closeMenu();
                           }}
        />
        
        <ConfirmationModal title={i18next.t('LOCK_SURVEY_CONFIRMATION', {aName:(selectedSurvey ? selectedSurvey.title : '')})}
                           width={500}
                           isOpen={lockSurveyConfirmationModalOpen}
                           reject={() => this.closeMenu()}
                           confirm={() => {
                             this.lockSurvey(selectedSurvey);
                             this.closeMenu();
                           }}
                           description={i18next.t('LOCK_SURVEY_CONFIRMATION_DESCRIPTION', {aName:(selectedSurvey ? selectedSurvey.title : '')})}
        />
      </div>
    )
  }
}
export default withTranslation()(PatientTools)
